#servicesContainer{
    font-size: 17px;
    font-family: Inter,sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 2rem;
}

#serviceImageDiv{
margin: 0 12rem;
margin-top: 2rem;
text-align: center;
}

.imageService{
    object-fit: contain;
    width: 100%;
}

@media only screen and (max-width: 600px){
    #serviceImageDiv {
      margin: 0rem;
      margin-top: 2rem;
    }
  }

  @media only screen and (min-width: 600px) and (max-width: 1200px) {
    #serviceImageDiv {
        margin: 0rem 4rem;
        margin-top: 2rem;
      }

      #thirdGrid{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    
  }


