#footer{
    background-color: #101218;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 3rem 0;
    font-size: 17px;
}
#f1{
  margin-bottom: 2rem;  
}

@media only screen and (max-width: 600px) {
    #footer{
        font-size: 14px;
        padding: 2rem 0;
    }
  }