#contactContainer{
    display: flex;
    flex-wrap: wrap;
    margin:  0 15rem;
}

#leftContactContainer{
flex: 1;
}

#rightContactContainer{
flex: 1;
margin-left: 5rem;
}

#contactForm{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 2rem;
    align-items: center;
}

#headingContact{
    font-size: 34px;
    font-family: Merriweather,serif;
}

.headingContact3{
    font-family: Merriweather,serif;
    font-size: 18px;
}

.paraContact3{
font-family: Inter,sans-serif;
font-size: 17px;
}

#socialIcons{
    display: flex;
    gap: 13px;
}

.alert {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #7E7EAB;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    display: block;
  }
  
  .close {
    cursor: pointer;
    margin-left: 10px;
  }

@media only screen and (max-width: 1000px){
    #contactContainer{
        margin: 0;
        display: block;
    }
    #leftContactContainer{
        margin: 0rem 1rem;
        }
    #rightContactContainer{
        margin: 0rem 1rem;
        }
  }