.containerAbout{
display: flex;
margin: 0rem 5rem;
}

.left-halfcontainerAbout{
flex: 1;
padding: 3rem;
}

.right-halfcontainerAbout{
flex: 1;
padding: 5rem;
margin-left: 5rem;
}

#aboutText{
    font-family: Inter,sans-serif;
    font-size: 17px;
    line-height: 2rem;
    text-align: justify;
}

#aboutText>a{
    color: #CE13CE;
}

#debImageAbout{
width: 100%;
height: auto;
margin-top: 1rem;
filter: brightness( 105% ) contrast( 96% ) saturate( 74% ) blur( 0px ) hue-rotate( 347deg );
border-radius: 4px 4px 4px 4px;
box-shadow: 10px 10px 0px 1px rgba(0,0,0,0.07);
}

@media only screen and (max-width: 600px){
    .right-halfcontainerAbout,.left-halfcontainerAbout{
        padding: 0rem;
    }

    .right-halfcontainerAbout{
        margin-left: 0;
    }

    #debImageAbout{
        width: 100%;
        height: auto;
        }

        .containerAbout{
            display: block;
            margin: 0 0.5rem;
        }

        #aboutText{
            line-height: 1.5rem;
            text-align: center;
        }
  }
@media only screen and (min-width: 600px)and (max-width: 1100px){
    .right-halfcontainerAbout,.left-halfcontainerAbout{
        padding: 2rem;
    }
    .right-halfcontainerAbout{
        margin-left: 3rem;
    }
    #debImageAbout{
        width: 100%;
        height: auto;
        }
.containerAbout{
    margin: 0;
}

        #aboutText{
            line-height: 1.5rem;
        }
  }