#topImage{
    position: relative;
    z-index: 1;
}

#topImage>img{
    object-fit: cover;
    filter: brightness(0.5);
}

#textAboveImage{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
}

#homeHeading{
    color: white;
    font-family: "Allura", cursive;
    font-size: 77px;
    font-weight: 400;
    text-align: center;
}
#homeHeading1{
    color: white;
    font-size: 35px;
    font-weight: 400;
   text-align: center;
   display: flex;
   justify-content: center;
}

#homeHeading1 ul {
    display: flex;
    padding: 0;
  }
  #homeHeading1 li {
    list-style-type: none;
    color: white;
  }
  #homeHeading1 li a:hover {
    color: #4169e1;
  }
  #homeHeading1 li a {
    text-decoration: none;
    display: block;
    width: 100%;
    color: white;
  }
#solidLine{
    border-top: 2px solid #2FC1FF;
}

#homeQuote{
    font-size: 20px;
    margin: 3rem 15rem;
    text-align: center;
    font-family: Inter;
    color: #4C5253;
}

#homeQuoteTitle{
    font-size: 17px;
    font-weight: bold;
    color: #4C5253;
}

.heading2{
    font-family: 'Merriweather', serif;
}

.bespokecontainer{
    text-align: center;
    margin-bottom: 2rem;
}

#beSpokeTitle{
    font-family: 'Merriweather', serif;
    text-align: center;
    font-size: 20px;
    font-style: italic;
    -webkit-text-stroke-color: #000;
    stroke: #000;
    text-shadow: 0px 0px 10px rgba(0,0,0,0.3);
}

.learnmoreButton {
  align-items: center;
  appearance: none;
  background-color: #3EB2FD;
  background-image: linear-gradient(1deg, #4F58FD, #149BF3 99%);
  background-size: calc(100% + 20px) calc(100% + 20px);
  border-radius: 100px;
  border-width: 0;
  box-shadow: none;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-flex;
  font-family: CircularStd,sans-serif;
  font-size: 1rem;
  height: auto;
  justify-content: center;
  line-height: 1.5;
  padding: 6px 20px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: background-color .2s,background-position .2s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: top;
  white-space: nowrap;
}

.learnmoreButton:active,
.learnmoreButton:focus {
  outline: none;
}

.learnmoreButton:hover {
  background-position: -20px -20px;
}

.learnmoreButton:focus:not(:active) {
  box-shadow: rgba(40, 170, 255, 0.25) 0 0 0 .125em;
}

#textAboveImage2{
    display: flex;
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
}

#leftHalfJourney{
flex: 1;
}

#rightHalfJourney{
    flex: 1;
}
#journey{
    font-family: 'Merriweather', serif;
    font-size: 34px;
    color: white;
}

#journeypara{
    font-family: Inter,sans-serif;
    font-size: 17px;
    color: white;
    margin-bottom: 1.6em;
    line-height: 2rem;
    margin-right: 8rem;
}
#solidLineatStart{
    margin-left: 0%;
    color: #2fc1ff;
}

.containerService {
    display: flex;
    margin: 2rem;
    margin-left: 3rem;
  }
  
  .left-halfcontainerService {
    flex: 35; /* 30% of the container's width */
    padding: 20px; /* Optional padding for content spacing */
  }

  #servicesText{
    font-size: 17px;
    font-family: Inter,sans-serif;
    color: #4C5253;
    line-height: 2rem;
    margin-bottom: 2rem;
    padding-right: 1rem;
  }

  .serviceImage1,.serviceImage2{
    width: 360px;
    height: 450px;
    border-radius: 4px 4px 4px 4px;
  }

  .serviceImage1{
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
  
  .right-halfcontainerService {
    flex: 65; /* 70% of the container's width */
    padding: 20px; /* Optional padding for content spacing */
  }

@media only screen and (max-width: 600px) {
    #topImage>img{
        object-fit: cover;
    }

    #homeHeading{
        font-size: 45px;
    }
    #homeHeading1{
        font-size: 20px;
    }
    #homeQuote{
        margin: 2rem 0;
        font-size: 14px;
    }
    .serviceImage1,.serviceImage2{
        width: 100%;
        height: 100%;
    }


  }
@media only screen and (min-width: 600px) and (max-width: 1000px) {
    #homeQuote{
        margin: 3rem 5rem;
    }
    
  }
@media only screen and (min-width: 1000px) and (max-width: 1300px) {
    .serviceImage1,.serviceImage2{
        width: 265px;
        height: 340px;
    }
    
  }
@media only screen and (max-width: 1000px) {
    #textAboveImage2{
        display: block;
    }

    #rightHalfJourney{
        margin-left: 4rem;
    }

    .containerService{
        flex-direction: column !important;
    }
    #servicesText{
        font-size: 12px;
        line-height: 1rem;
    }
    

    .left-halfcontainerService {
        flex: 1; 
        padding: 0px; 
        margin-bottom: 1rem;
      }
    .right-halfcontainerService {
        flex: 1; 
        padding: 0px; 
      }
   
  }

  @media only screen and (min-width: 1700px){
    .containerService {
      margin: 0rem 12rem;
    }
  }